import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };

export default function useEmployeeList(onboarding) {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    onboarding === 'onboarding' && {
      ...{ key: 'hiredate', label: i18nT(`Hired on`), sortable: true, thClass: "sk-header-content" },
    },
    onboarding === 'offboarding' && {
      ...{ key: 'OnboardingAt', label: i18nT(`Hired on`), sortable: true, thClass: "sk-header-content" },
    },
    onboarding === 'offboarding' && {
      ...{ key: 'OffboardingAt', label: i18nT(`Departure date`), sortable: true, thClass: "sk-header-content" },
    },
    onboarding === 'offboarding' && {
      ...{ key: 'OffboardingNotice', label: i18nT(`Notice date`), sortable: true, thClass: "sk-header-content" },
    },
    { key: 'created', label: i18nT(`Created`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ];
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const department = ref('');
  const group = ref('');
  const employees = ref([]);
  const matchStage = ref();

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      department,
      group,
      isSortDirDesc,
      sortBy,
      matchStage,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    };

    if (department.value != null) {
      params['filters[department]'] = department.value;
    }
    if (group.value != null) {
      params['filters[group]'] = group.value;
    }

    params['pipeline'] = onboarding;
    params['stage'] = matchStage.value;
    axios
      .get(`employees`, {
        params,
      })
      .then(({ data }) => {
        employees.value = data.data.records;
        callback(data.data.records ? data.data.records : []);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    department,
    group,
    employees,
    matchStage,
    refetchData,
  };
}
